import { StepperComponent } from './stepper/stepper.component'
import { StepComponent } from './stepper/step.component'
import { AuthSecurityWrapperComponent } from '../modules/auth/auth-security-wrapper/auth-security-wrapper.component'

import { PanelComponent } from './layouts/panel/panel.component'
import { ExpandingPanelComponent } from './layouts/expanding-panel/expanding-panel.component'
import { SplashscreenComponent } from './splashscreen/splashscreen.component'

import { AgentLoginComponent } from './layouts/agent-login/agent-login.component'

import { FormErrorComponent } from './controls/form-error/form-error.component'
import { BannerComponent } from './banner/banner.component'
import { ButtonComponent } from './controls/button/button.component'
import { ClickableLinkComponent } from './controls/clickable-link/clickable-link.component'
import { ImageComponent } from './controls/image/image.component'
import { LinkButtonComponent } from './controls/link-button/link-button.component'
import { RadioSquareComponent } from './controls/radio-square/radio-square.component'
import {
  CarRentalCredentialsComponent
} from './whitelabel-credentials-modes/car-rental-credentials/car-rental-credentials.component'
import {
  SubscriberNumberCredentialsComponent
} from './whitelabel-credentials-modes/subscriber-number-credentials/subscriber-number-credentials.component'
import { SelectComponent } from './controls/select/select.component'
import { AriaLinkButtonComponent } from './controls/aria-link/aria-link-button.component'
import { SkipToContentComponent } from './controls/skip-to-content/skip-to-content.component'
import {
  ThirdPartyCookiesInstructionsComponent
} from './third-party-cookies-instructions/third-party-cookies-instructions.component'
import { LoginFailAssistantComponent } from '../modules/auth/login-fail-assitant/login-fail-assistant.component'
import { ReviewCardComponent } from '../modules/location/aar/review/review-card.component'
import { ReviewListComponent } from '../modules/location/aar/review/review-list.component'
import { GoogleGeocodeService } from '../modules/location/google-geocode/google-geocode.service'
import { EvStationNotesComponent } from '../modules/location/ev-station/station-notes/station-notes.component';
import { ElectricVehicleComponent } from '../modules/location/ev-station/electric-vehicle/electric-vehicle.component'
import { EvStationConnectorTypeComponent } from '../modules/location/ev-station/connector-type/connector-type.component'
import {
  EvStationConnectorTypeListComponent
} from '../modules/location/ev-station/connector-type-list/connector-type-list.component'
import { StepperProgressBarComponent } from './stepper-progress-bar/stepper-progress-bar.component'
import { BumpOutAnimationComponent } from './stepper-progress-bar/bump-out-animation/bump-out-animation.component'
import { FacilityStatusComponent } from './facility-status/facility-status.component';
import { FacilityNextDateComponent } from './facility-next-date/facility-next-date.component';
import { LogoutComponent } from '../modules/auth/logout/logout.component';
import { AbTestSwitcherComponent } from './ab-test-switcher/ab-test-switcher.component'
import { InputLicensePlateComponent } from './controls/input-license-plate/input-license-plate.component'
import { InputUsStateComponent } from './controls/input-us-state/input-us-state.component'
import { RECAPTCHA_LOADER_OPTIONS } from 'ng-recaptcha';
import { LOCALE_ID } from '@angular/core';
import { LocaleSelectorComponent } from './i18n/locale-selector/locale-selector.component'
import { UnitSystemSelectorComponent } from './i18n/unit-system-selector/unit-system-selector.component'
import { HeaderComponent } from './layouts/header/header.component'
import { ClosableHeaderComponent } from './layouts/closable-header/closable-header.component'
import { provideNgxMask } from 'ngx-mask';
import { MemberCredentialsComponent } from '../modules/auth/member-credentials/member-credentials.component'
import { LoginLiteComponent } from '../modules/auth/login-forms/login-lite.component';
import { MemberNumberComponent } from '../modules/auth/login-forms/member-number.component';
import { FormHelperComponent } from './controls/form-helper/form-helper.component';
import { LocationCardComponent } from '../modules/breakdown-location/location-card/location-card.component'
import { HeaderRedesignComponent } from './layouts/header-redesign/header/header.component'
import {
  LocationAddressComponent
} from '../modules/breakdown-location/components/location-address/location-address.component'
import { LetsStartSheetComponent } from '../modules/location/lets-start-sheet/lets-start-sheet.component';
import {
  TowLocationDiscountBannerComponent
} from '../modules/location/tow-location-discount-banner/tow-location-discount-banner.component'
import { PhoneNumberLinkComponent } from './phone-number/phone-number-link.component'
import { DestinationTypeComponent } from '../modules/location/destination-type/destination-type.component'
import { ChargingLevelComponent } from '../modules/location/ev-station/charging-level/charging-level.component'
import {
  ChargingLevelListComponent
} from '../modules/location/ev-station/charging-level-list/charging-level-list.component'
import { ShopCardComponent } from '../modules/location/shop-card/shop-card.component'
import { ShopDetailsModalComponent } from '../modules/ui/dialog/prompts/shop-details-modal/shop-details-modal.component'
import { ReviewsCountComponent } from './reviews-count/reviews-count.component'
import {
  CustomDestinationCardComponent
} from '../modules/location/custom-destination-card/custom-destination-card.component'
import { AlphabetScrollListComponent } from './alphabet-scroll-list/alphabet-scroll-list.component'
import { LogoComponent } from './layouts/header-redesign/logo/logo.component'
import {
  WhiteLabelCredentialsComponent
} from './layouts/white-label-credentials/white-label-credentials.component';
import {
  VinMileageCredentialsComponent
} from './whitelabel-credentials-modes/vin-mileage-credentials/vin-mileage-credentials.component';
import {
  OdometerConfirmationComponent
} from './whitelabel-credentials-modes/odometer-confirmation/odometer-confirmation.component';

export function sharedComponents() {
  return [
    StepperComponent,
    StepComponent,
    AuthSecurityWrapperComponent,
    ExpandingPanelComponent,
    PanelComponent,
    SplashscreenComponent,
    MemberCredentialsComponent,
    MemberNumberComponent,
    LoginLiteComponent,
    FacilityNextDateComponent,
    FacilityStatusComponent,
    FormErrorComponent,
    FormHelperComponent,
    BannerComponent,
    ButtonComponent,
    LinkButtonComponent,
    ClickableLinkComponent,
    RadioSquareComponent,
    AgentLoginComponent,
    WhiteLabelCredentialsComponent,
    CarRentalCredentialsComponent,
    SubscriberNumberCredentialsComponent,
    SelectComponent,
    AriaLinkButtonComponent,
    SkipToContentComponent,
    LoginFailAssistantComponent,
    ThirdPartyCookiesInstructionsComponent,
    ReviewCardComponent,
    ReviewListComponent,
    ReviewsCountComponent,
    ChargingLevelComponent,
    ChargingLevelListComponent,
    EvStationNotesComponent,
    ElectricVehicleComponent,
    EvStationConnectorTypeComponent,
    EvStationConnectorTypeListComponent,
    StepperProgressBarComponent,
    BumpOutAnimationComponent,
    LogoutComponent,
    AbTestSwitcherComponent,
    InputLicensePlateComponent,
    InputUsStateComponent,
    LocaleSelectorComponent,
    UnitSystemSelectorComponent,
    HeaderComponent,
    LocationCardComponent,
    LocationAddressComponent,
    LetsStartSheetComponent,
    TowLocationDiscountBannerComponent,
    PhoneNumberLinkComponent,
    DestinationTypeComponent,
    ShopCardComponent,
    ShopDetailsModalComponent,
    CustomDestinationCardComponent,
    AlphabetScrollListComponent,
    VinMileageCredentialsComponent,
    OdometerConfirmationComponent,
  ]
}

export function sharedProviders() {
  return [
    provideNgxMask(),
    GoogleGeocodeService,
    [
      {
        provide: RECAPTCHA_LOADER_OPTIONS,
        useFactory: () => ({
          onBeforeLoad(url) {
            url.searchParams.set('hl', localStorage.getItem('locale'))
            return { url }
          },
        }),
        deps: [LOCALE_ID],
      },
    ],
  ]
}

export function sharedModules() {
  return [
    HeaderRedesignComponent,
    ClosableHeaderComponent,
    LogoComponent,
    ImageComponent,
  ]
}
